/**
 * This file is part of the nmh-nettsted-2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 * Add lin ehere to trigger new build.
 */

import './styles/main.scss';

// Import CSS modules
import 'modules/quote.module.scss';
import 'modules/image.module.scss';
import 'modules/text.module.scss';
import 'modules/header.module.scss';
import 'modules/banner.module.scss';
import 'modules/breadcrumbs.module.scss';
import 'modules/content-header.module.scss';
import 'modules/content-footer.module.scss';
import 'modules/section-header.module.scss';
import 'modules/metadata.module.scss';
import 'modules/footer.module.scss';
import 'modules/video.module.scss';
import 'modules/personheader.module.scss';
import 'modules/personlist.module.scss';
import 'modules/person-publications.module.scss';
import 'modules/entrylist.module.scss';
import 'modules/campaign.module.scss';
import 'modules/tags.module.scss';
import 'modules/number-facts.module.scss';
import 'modules/library-search.module.scss';
import 'modules/list-employees.module.scss';
import 'modules/list-studies.module.scss';
import 'modules/simplelist-studies.module.scss';
import 'modules/simple-list.module.scss';
import 'modules/alternating-list.module.scss';
import 'modules/widget.module.scss';
import 'modules/text-with-image.module.scss';
import 'modules/byline.module.scss';
import 'modules/form.module.scss';
import 'modules/frontpage/quicklinks.module.scss';
import 'modules/frontpage/entrance.module.scss';
import 'modules/frontpage/featured-link.module.scss';
import 'modules/praxis-header.module.scss';
import 'modules/praxis-article.module.scss';
import 'modules/podcast.module.scss';
import 'modules/praxis-concept.module.scss';
import 'modules/masonry-list.module.scss';
import 'modules/foyer.module.scss';
import 'modules/sticky.module.scss';
import 'modules/newsletter.module.scss';

function hasTouch() {
  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && window.document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-'];
  const media = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
    ''
  );

  // window.matchMedia('(touch-enabled),(-webkit-touch-enabled),(-moz-touch-enabled),(-o-touch-enabled),(-ms-heartz)').matches
  return window.matchMedia(media).matches;
}

if (hasTouch()) {
  document.body.classList.remove('no-touch');
}

function setVH() {
  window.document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  );
}

global.addEventListener('resize', setVH);

setVH();

require('./scripts/index');
