/**
 * This file is part of the nmh-nettsted-2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import 'intersection-observer';
import loadjs from 'loadjs';

import registerComponents from 'registerComponents';
import registerDecorators from 'registerDecorators';
import { addEventListeners, entryListBackgrounds } from 'utils/DOM';

let LOADED = false;
global.registerComponents = true;

if (
  process.env.NODE_ENV === 'production' &&
  process.env.SENTRY_AUTH_TOKEN != 'undefined'
) {
  Sentry.init({
    dsn:
      'https://069cfe79f08c4db7b2616c79c0fdc8fc@o463742.ingest.sentry.io/5469126',
    //integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    //tracesSampleRate: 1.0,
    //https://docs.sentry.io/platforms/javascript/configuration/options/
    //https://docs.sentry.io/platforms/javascript/performance/
  });
}

function bootstrap() {
  /**
   * Register components
   */
  if (global.registerComponents) {
    global.registerComponents = false;
    registerComponents();
    registerDecorators();
  }

  document.documentElement.classList.remove('js-loading');
  document.documentElement.classList.add('js-loaded');

  picturefill();
  objectFitImages();
  addEventListeners();
  entryListBackgrounds()

  // Load :focus-visible polyfill if not supported
  try {
    document.querySelector(':focus-visible');
  } catch {
    loadjs(
      'https://cdn.jsdelivr.net/npm/focus-visible@5.2.0/dist/focus-visible.min.js'
    );
  }
}

function load() {
  if (LOADED) return;
  LOADED = true;
  bootstrap();
}

document.addEventListener('DOMContentLoaded', load, false);
window.addEventListener('load', load, false);
