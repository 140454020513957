class OverlappingElements {
  constructor(node, options = {}) {
    this.node = node;
    this.nodeRect = this.node.getBoundingClientRect(); // Set initial clientRect of node
    this.active = false;
    this.height = window.innerHeight;
    this.elementQueries = options.elements || '';

    this.isOverlapping = false;
    this.overlapClass = options.overlapClass || 'overlapping';
    this.intersecting = {}; // Holds track of intersecting status per element

    this.elements = document.querySelectorAll(this.elementQueries);
    this.elements.forEach(
      (element) => (this.intersecting[element.className] = false)
    );

    // Create mediaQueryListener to detect when decorator is active or not
    this.mql = window.matchMedia(`(min-width: 80rem)`);
    this.mql.addEventListener('change', this.onMQLChange);

    // Add observer if mql matches
    if (this.mql.matches) {
      this.active = true;
      this.addObserver();
    }

    // Observe when node resizes, to update nodeRect
    this.nodeObserver = new ResizeObserver(this.updateNodeRect);
    this.nodeObserver.observe(this.node);

    // Add mutation observer to listen for new elements added to body
    this.mutationObserver = new MutationObserver(this.onMutation);
    this.mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  onMutation = (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        // A child node has been added or removed, select elements again
        const newElements = document.querySelectorAll(this.elementQueries);

        // Add any new elements
        Object.values(newElements).forEach((element) => {
          if (typeof this.intersecting[element.className] === 'undefined') {
            this.intersecting[element.className] = false;
            this.elements = newElements;
            this.observer?.observe(element);
          }
        });
      }
    }
  };

  onMQLChange = (e) => {
    let isActive = e.matches;

    if (this.active && !isActive) {
      this.clearObserver();
    } else if (!this.active && isActive) {
      this.addObserver();
    }

    this.active = isActive;
  };

  updateNodeRect = (entries) => {
    this.nodeRect = this.node.getBoundingClientRect();

    if (this.active) {
      this.clearObserver();
      this.height = window.innerHeight;
      this.addObserver();
    }
  };

  clearObserver = () => {
    if (this.observer) {
      this.elements.forEach((element) => this.observer.unobserve(element));
      this.observer.disconnect();
      delete this.observer;
    }
  };

  addObserver = () => {
    if (this.height) {
      // Detect overlap when 1/4 of node is touched
      const diff = this.nodeRect.height / 4;
      const rootMargin = [
        `${-1 * (this.nodeRect.top + diff)}px`,
        '0px',
        `${
          -1 * (this.height - this.nodeRect.top - this.nodeRect.height + diff)
        }px`,
        '0px',
      ].join(' ');

      try {
        // Create observer with calculated rootMargin
        this.observer = new IntersectionObserver(this.detectOverlap, {
          rootMargin,
          threshold: 0,
        });

        this.elements.forEach((element) => this.observer.observe(element));
      } catch (e) {
        // Catch SyntaxErrors in rootMargin which can trigger in some resize situations
      }
    }
  };

  detectOverlap = (entries, observer) => {
    entries.forEach((entry) => {
      // Update intersection status for target element
      this.intersecting[entry.target.className] = entry.isIntersecting;
    });

    let isIntersecting = Object.values(this.intersecting).some(
      (s) => s === true
    );

    if (isIntersecting && !this.isOverlapping) {
      // toggle data-overlapping attribute on this.node
      this.node.setAttribute('data-overlapping', true);

      // this.node.classList.add(this.overlapClass);
    } else if (!isIntersecting && this.isOverlapping) {
      this.node.removeAttribute('data-overlapping');
      // this.node.classList.remove(this.overlapClass);
    }

    this.isOverlapping = isIntersecting;
  };
}

export default (node, props) => new OverlappingElements(node, props);
