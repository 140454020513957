/*
 * This file is part of the Norges Musikkhøyskole Nettsted 2020 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { FocusScope, useFocusManager } from 'react-aria';
import useKeys from 'effects/keys';
import shortid from 'shortid';
import slugify from '@sindresorhus/slugify';

import styles from './Menu.module.scss';

function MenuInner({ items, open, setOpen }) {
  const tabIndex = open ? 0 : -1;
  const ref = useRef(null);
  const focusManager = useFocusManager();
  const [menuId] = useState(shortid.generate);

  const isWideLayout = useMediaQuery({
    query: `(min-width: ${styles['page-width-desktop-wide']})`,
  });

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        focusManager.focusNext({ wrap: true });
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        focusManager.focusPrevious({ wrap: true });
        break;
    }
  };

  const renderItems = () => {
    return items.map((list) => {
      const id = slugify(`menu_item_${list.heading}`);
      return (
        <div className={styles.list} key={`list_${list.heading}`}>
          <h5 id={id} aria-hidden>
            {list.heading}
          </h5>
          <ul aria-labelledby={id}>
            {list.items.map((item) => (
              <li key={`${list.heading}_${item.text}`}>
                <a href={item.url} tabIndex={tabIndex} onKeyDown={onKeyDown}>
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    });
  };

  useKeys('menu', open, {
    esc: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (open) {
      // Set data-menu-open from body
      document.body.setAttribute('data-menu-open', true);
      if (ref.current) {
        const first = ref.current.querySelector('a:first-of-type');
        if (first) {
          first.focus();
        }
      }
    } else {
      // Remove data-menu-open from body
      document.body.removeAttribute('data-menu-open');
    }
  }, [open]);

  useEffect(() => {
    if (isWideLayout && open) {
      disablePageScroll();
    } else {
      enablePageScroll();
    }
  }, [isWideLayout, open]);

  return (
    <>
      <button
        key="menu-button"
        className={classNames('header__menu', { 'header__menu-open': open })}
        aria-label="Menu"
        aria-haspopup="true"
        aria-controls={menuId}
        aria-expanded={open}
        onClick={() => setOpen(!open)}
        onKeyDown={(e) => {
          open ? onKeyDown(e) : null;
        }}
      ></button>
      <nav
        id={menuId}
        className={classNames(
          styles.main,
          'styled-links',
          'styled-links-reversed',
          { [styles.open]: open }
        )}
        ref={ref}
      >
        <div className={styles.wrapper} data-scroll-lock-scrollable>
          <div className="grid">{renderItems()}</div>
        </div>
      </nav>
    </>
  );
}

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
MenuInner.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
          text: PropTypes.string,
        })
      ),
    })
  ),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
MenuInner.defaultProps = {
  items: [],
  open: false,
  setOpen: () => {},
};

function Menu(props) {
  const [open, setOpen] = useState(false);

  return (
    <FocusScope contain={open} restoreFocus>
      <MenuInner {...props} open={open} setOpen={setOpen} />
    </FocusScope>
  );
}

export default Menu;
