import React, { lazy, Suspense } from 'react';

import registerReact from 'utils/registerReact';

/**
 * React components
 */
import Menu from 'components/Menu/Menu';

const withLazyComponent = (LazyComponent) => {
  return (props) => (
    <Suspense fallback={<span></span>}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

const Slideshow = withLazyComponent(
  lazy(() =>
    import(
      'components/Slideshow/Slideshow.js' /* webpackChunkName: "Slideshow" */
    )
  )
);

const GalleryGrid = withLazyComponent(
  lazy(() =>
    import(
      'components/GalleryGrid/GalleryGrid.js' /* webpackChunkName: "GalleryGrid" */
    )
  )
);

const Accordion = withLazyComponent(
  lazy(() =>
    import(
      'components/Accordion/Accordion.js' /* webpackChunkName: "Accordion" */
    )
  )
);

const Carousel = withLazyComponent(
  lazy(() =>
    import('components/Carousel/Carousel.js' /* webpackChunkName: "Carousel" */)
  )
);

const EntrySwiper = withLazyComponent(
  lazy(() =>
    import(
      'components/EntrySwiper/EntrySwiper.js' /* webpackChunkName: "EntrySwiper" */
    )
  )
);

const VideoHeader = withLazyComponent(
  lazy(() =>
    import(
      'components/VideoHeader/VideoHeader.js' /* webpackChunkName: "VideoHeader" */
    )
  )
);

const VideoPlayer = withLazyComponent(
  lazy(() =>
    import(
      'components/VideoPlayer/VideoPlayer.js' /* webpackChunkName: "VideoPlayer" */
    )
  )
);

const AudioPlayers = withLazyComponent(
  lazy(() =>
    import(
      'components/AudioPlayers/AudioPlayers.js' /* webpackChunkName: "AudioPlayers" */
    )
  )
);

const People = withLazyComponent(
  lazy(() =>
    import('components/People/People.js' /* webpackChunkName: "People" */)
  )
);

const Filter = withLazyComponent(
  lazy(() =>
    import('components/Filter/Filter.js' /* webpackChunkName: "Filter" */)
  )
);

const Pagination = withLazyComponent(
  lazy(() =>
    import(
      'components/Pagination/Pagination.js' /* webpackChunkName: "Pagination" */
    )
  )
);

const Search = withLazyComponent(
  lazy(() =>
    import('components/Search/Search.js' /* webpackChunkName: "Search" */)
  )
);

const Freeform = withLazyComponent(
  lazy(() =>
    import('components/Freeform/Freeform.js' /* webpackChunkName: "Freeform" */)
  )
);

const ModalForm = withLazyComponent(
  lazy(() =>
    import(
      'components/ModalForm/ModalForm.js' /* webpackChunkName: "ModalForm" */
    )
  )
);

const LoadMore = withLazyComponent(
  lazy(() =>
    import('components/LoadMore/LoadMore.js' /* webpackChunkName: "LoadMore" */)
  )
);

const Feedback = withLazyComponent(
  lazy(() =>
    import('components/Feedback/Feedback.js' /* webpackChunkName: "Feedback" */)
  )
);

const TopicStructure = withLazyComponent(
  lazy(() =>
    import(
      'components/TopicStructure/TopicStructure.js' /* webpackChunkName: "TopicStructure" */
    )
  )
);

const AnniversaryLogo = withLazyComponent(
  lazy(() =>
    import(
      './components/AnniversaryLogo/AnniversaryLogo.js' /* webpackChunkName: "AnniversaryLogo" */
    )
  )
);

const Application = withLazyComponent(
  lazy(() =>
    import(
      'components/Application/Application.js' /* webpackChunkName: "Application" */
    )
  )
);

const Foyerscreen = withLazyComponent(
  lazy(() =>
    import(
      'components/Foyerscreen/Foyerscreen.js' /* webpackChunkName: "Foyerscreen" */
    )
  )
);

const StickyMenu = withLazyComponent(
  lazy(() =>
    import('components/StickyMenu/StickyMenu.js' /* webpackChunkName: "StickyMenu" */)
  )
);

export default () => {
  registerReact('slideshow-component', Slideshow);
  registerReact('gallery-grid-component', GalleryGrid);
  registerReact('menu-component', Menu);
  registerReact('accordion-component', Accordion);
  registerReact('carousel-component', Carousel);
  registerReact('entryswiper-component', EntrySwiper);
  registerReact('videoheader-component', VideoHeader);
  registerReact('videoplayer-component', VideoPlayer);
  registerReact('audioplayers-component', AudioPlayers);
  registerReact('people-component', People);
  registerReact('filter-component', Filter);
  registerReact('pagination-component', Pagination);
  registerReact('search-component', Search);
  registerReact('freeform-component', Freeform);
  registerReact('modalform-component', ModalForm);
  registerReact('loadmore-component', LoadMore);
  registerReact('feedback-component', Feedback);
  registerReact('topic-structure-component', TopicStructure);
  registerReact('anniversary-logo-component', AnniversaryLogo);
  registerReact('application-component', Application);
  registerReact('foyerscreen-component', Foyerscreen);
  registerReact('sticky-menu-component', StickyMenu);
};
