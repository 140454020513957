import 'mutationobserver-polyfill';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { getProps, getChildren } from 'utils/DOM';

const listeners = [];

function renderComponent(node, Component) {
  const props = getProps(node);
  props.children = getChildren(node);
  render(<Component {...props} container={node} />, node);
}

function checkAdded(added) {
  if (['STYLE', '#text'].indexOf(added.nodeName) === -1) {
    listeners.forEach(({ selector, Component }) => {
      try {
        const nodes = added.parentNode.querySelectorAll(selector);
        Array.prototype.forEach.call(nodes, node => {
          renderComponent(node, Component);
        });
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

function checkRemoved(removed) {
  if (['STYLE', '#text'].indexOf(removed.nodeName) === -1) {
    listeners.forEach(({ selector }) => {
      try {
        const nodes = removed.parentNode.querySelectorAll(selector);
        Array.prototype.forEach.call(nodes, node => {
          unmountComponentAtNode(node);
        });
        /* eslint-disable no-empty */
      } catch (e) {}
    });
  }
}

function updateAttributes(target) {
  listeners.forEach(({ selector, Component }) => {
    try {
      if (selector.toLowerCase() === target.nodeName.toLowerCase()) {
        renderComponent(target, Component);
      }
      /* eslint-disable no-empty */
    } catch (e) {}
  });
}

const observer = new MutationObserver(mutationsList => {
  for (const mutation of mutationsList) {
    if (mutation.type == 'childList') {
      if (mutation.removedNodes.length > 0) {
        mutation.removedNodes.forEach(checkRemoved);
      }

      if (mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach(checkAdded);
      }
    } else if (mutation.type == 'attributes') {
      updateAttributes(mutation.target);
    }
  }
});

observer.observe(document, {
  childList: true,
  subtree: true,
  attributes: true,
});

export default (selector, Component) => {
  listeners.push({
    selector,
    Component,
  });

  try {
    const nodes = document.querySelectorAll(selector);
    Array.prototype.forEach.call(nodes, node => {
      renderComponent(node, Component);
    });
    /* eslint-disable no-empty */
  } catch (e) {}
};
