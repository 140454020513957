function IsNumeric(val) {
  return Number(parseFloat(val)) == val;
}

export function attributeNameToPropertyName(attributeName) {
  return attributeName
    .replace(/^(x|data)[-_:]/i, '')
    .replace(/[-_:](.)/g, (x, chr) => chr.toUpperCase());
}

export function parseAttributeValue(value) {
  if (!value) {
    return null;
  }

  if (IsNumeric(value)) {
    return Number(parseFloat(value));
  }

  if (value === 'true') return true;
  if (value === 'false') return false;

  // Support attribute values with newlines
  let newValue = value.replace(/[\n\r]/g, '');

  const pointerRegexp = /^{.*?}$/i;
  const jsonRegexp = /^{{2}.*}{2}$/;
  const jsonArrayRegexp = /^{\[.*\]}$/;

  const pointerMatches = newValue.match(pointerRegexp);
  const jsonMatches =
    newValue.match(jsonRegexp) || newValue.match(jsonArrayRegexp);

  // console.log(newValue, isNumber(newValue));

  if (jsonMatches) {
    newValue = JSON.parse(jsonMatches[0].replace(/^{|}$/g, ''));
  } else if (pointerMatches) {
    /* eslint-disable no-eval */
    newValue = eval(pointerMatches[0].replace(/[{}]/g, ''));
  }

  return newValue;
}

export function getProps(node) {
  const props = {};

  for (let i = 0; i < node.attributes.length; i++) {
    const attribute = node.attributes[i];
    const name = attributeNameToPropertyName(attribute.name);
    props[name] = parseAttributeValue(attribute.value);
  }

  props.container = node;

  return props;
}

export function getChildren(node) {
  const fragment = document.createDocumentFragment();
  const cache = document.createDocumentFragment();

  const childNodes = node.childNodesCache || node.childNodes;
  for (let key in childNodes) {
    if (childNodes.hasOwnProperty(key)) {
      if (childNodes[key].nodeName.substr(0, 1) !== '#') {
        fragment.appendChild(childNodes[key].cloneNode(true));
        cache.appendChild(childNodes[key].cloneNode(true));
      }
    }
  }

  if (typeof node.childNodesCache === 'undefined') {
    node.childNodesCache = cache.childNodes;
  }
  return fragment;
}

// https://gist.github.com/ryanmorr/58495cf47fc783bac8c8783c9e517e0b
export function parseHTML(html) {
  const template = document.createElement('template');
  if ('content' in template) {
    template.innerHTML = html;
    return document.importNode(template.content, true);
  }
  const frag = document.createDocumentFragment();
  const div = document.createElement('div');
  div.innerHTML = html;
  while (div.firstChild) {
    frag.appendChild(div.firstChild);
  }
  return frag;
}

export function toggle(e, selector) {
  const element = document.querySelector(selector);

  if (element) {
    const button = e.target;
    const open = button.dataset.open == 'true';
    const nextOpen = !open;
    button.dataset.open = nextOpen;
    element.setAttribute('open', nextOpen);
  }
}

export function entryListBackgrounds() {
  const entryListItems = document.querySelectorAll(
    '.entrylist__fallback-image'
  );
  // console.log(entryListItems);
  entryListItems.forEach(function (item) {
    const parent = item.parentNode;
    if (parent) {
      parent.classList.add('entrylist__background-image');
      parent.setAttribute(
        'style',
        `background-image: url(${item.dataset.background});`
      );
    }
    // console.log(item.dataset.background);
    // if (item.dataset.background) {
    //   item.parentNode.style.backgroundImage = item.dataset.background;
    // }
    // item.parentNode.classList.add('entrylist__background-image')
  });
}

// function handleMenuButton() {
//   const menuButton = document.querySelector('.header__menu');
//   const menuComponent = document.querySelector('menu-component');

//   const toggleMenu = (state = null) => {
//     let nextOpen = state;
//     if (nextOpen === null) {
//       // State is not forced, fetch from current button state and invert
//       const open = menuButton.dataset.open == 'true';
//       nextOpen = !open;
//     }
//     menuButton.dataset.open = nextOpen;
//     if (menuComponent) menuComponent.setAttribute('open', nextOpen);
//   };

//   if (menuButton) {
//     menuButton.addEventListener('click', () => toggleMenu());
//     if (menuComponent)
//       menuComponent.addEventListener('close', () => toggleMenu(false));
//   }
// }

function handlePraxisContentButton() {
  const contentButton = document.querySelector('.btn-sticky-content');
  const contentPanel = document.querySelector('.sticky__chapter-panel');

  // const contentList = document.querySelector('.sticky__chapter-list');
  // if ( contentPanel && contentList ) {
  //   contentPanel.style.top = 40 + contentList.offsetHeight +'px';
  // }

  if (contentButton && contentPanel) {
    contentButton.addEventListener('click', (e) => {
      e.preventDefault();
      contentPanel.classList.toggle('sticky__panel-open');
    });
  }
}

function handlePraxisTypelinksButton() {
  const contentButton = document.querySelector('.btn-praxis-theme-links');
  const contentPanel = document.querySelector('.praxis-header__theme-links');

  if (contentButton && contentPanel) {
    contentButton.addEventListener('click', (e) => {
      e.preventDefault();
      contentPanel.classList.toggle('praxis-header__theme-links-open');
    });
  }
}

export function addEventListeners() {
  // Add listeners to toggle menu open state
  // handleMenuButton();
  handlePraxisContentButton();
  handlePraxisTypelinksButton();

  // Add event listeners to input file buttons
  const inputButtons = document.querySelectorAll('.form__file-button');
  inputButtons.forEach((button) => {
    const input = button.querySelector('input[type=file]');
    const span = button.querySelector('span');
    const defaultCaption = span.innerHTML;
    if (input && span) {
      input.addEventListener('change', (e) => {
        let caption = null;
        if (input.files && input.files.length > 1) {
          caption = (span.getAttribute('data-multiple-caption') || '').replace(
            '{count}',
            input.files.length
          );
        } else {
          caption = input.value.split('\\').pop();
        }

        if (caption) {
          span.innerHTML = caption;
        } else {
          span.innerHTML = defaultCaption;
        }
      });
    }
  });
}

export default {
  attributeNameToPropertyName,
  parseAttributeValue,
  getProps,
  getChildren,
  parseHTML,
  toggle,
  entryListBackgrounds,
};
