import registerDecoration from 'utils/registerDecoration';

/**
 * Decorators
 */
import OverlappingElements from 'decorators/overlapping-elements';

export default () => {
  // Array of selectors that should invert logo/menu elements
  const elements = [
    'body.inverted',
    'videoheader-component',
    '.header__inverted',
    '.footer__main',
    '.VideoHeader__main',
    '.EntrySwiper__wrapper',
    '.full-width-desktop.campaign__palette-black',
    '.full-width-desktop.campaign__palette-dark-grey',
    '.full-width-desktop.campaign__palette-purple',
    '.full-width-desktop.campaign__palette-green',
    '.campaign__frontpage.campaign__palette-black',
    '.campaign__frontpage.campaign__palette-dark-grey',
    '.campaign__frontpage.campaign__palette-purple',
    '.campaign__frontpage.campaign__palette-green',
    '.Carousel__inverted',
  ].join(',');

  // Add class when elements overlap target node
  registerDecoration('.header__logo', OverlappingElements, {
    elements,
    overlapClass: 'header__logo-overlapping',
  });

  registerDecoration('.header__menu', OverlappingElements, {
    elements,
    overlapClass: 'header__menu-overlapping',
  });
};
